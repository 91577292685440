<template>
  <el-container class="company-container">
    <el-header class="banner">
      <span class="company-name">{{ user.companyName }}</span>
      <span style="margin-right:10px;">{{ user.name }} ({{ user.email }}) </span>
      <el-tooltip placement="top" :content="$t('common.button.logout')">
        <el-icon style="margin-right:10px;cursor: pointer;font-weight: bold;font-size: 18px; top:3px;" @click="logout">
          <i class="bi bi-box-arrow-right"></i>
        </el-icon>
      </el-tooltip>
    </el-header>
    <el-container class="company-inner-container">
      <el-aside class="menu">
        <el-menu text-color="#ffffff" :default-active="$route.name" :router="true">
          <el-menu-item index="CompanyHome" :route="{ name: 'CompanyProfile' }">
            <el-icon>
              <i class="bi bi-house-fill" />
            </el-icon>
            <span>{{ $t("company.menu.home") }}</span>
          </el-menu-item>
          <el-menu-item index="CompanyNew" :route="{ name: 'CompanyNew' }">
            <el-icon>
              <i class="bi bi-file-earmark-text-fill" />
            </el-icon>
            <span>{{ $t("company.menu.new") }}</span>
          </el-menu-item>
          <el-menu-item index="CompanyChange" :route="{ name: 'CompanyChange' }">
            <el-icon>
              <i class="bi bi-arrow-left-right" />
            </el-icon>
            <span>{{ $t("company.menu.change") }}</span>
          </el-menu-item>
          <el-menu-item index="CompanyEnquiry" :route="{ name: 'CompanyEnquiry' }">
            <el-icon>
              <i class="bi bi-card-list" />
            </el-icon>
            <span>{{ $t("company.menu.enquiry") }}</span>
          </el-menu-item>
          <el-menu-item index="staffTerminationRecord" :route="{ name: 'staffTerminationRecord' }">
            <el-icon>
              <i class="bi bi-credit-card-fill" />
            </el-icon>
            <span>{{ $t("company.menu.cardCancellation") }}</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<style scoped>
.banner {
  padding: 0px 10px;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  text-align: right;
  background-color: rgba(17, 37, 74, 0.9);
  color: white;
}

.banner>.company-name {
  float: left;
  margin-left: 15px;
}

.menu {
  background-color: rgba(17, 37, 74, 0.85);
  width: 250px;
  font-family: "Times New Roman";
}

.menu .el-menu {
  background-color: transparent;
  border-right: none;
}

.el-menu-item.is-active,
.el-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 709px) {
  .el-main {
    --el-main-min-height: 169px
  }

  .el-container.company-container {
    min-height: calc(100vh - 60px - 60px -17px);
  }

  .el-container.company-inner-container {
    min-height: calc(100vh - 60px - 60px - 32px - 17px);
  }
}

@media screen and (min-width: 710px) {
  .el-main {
    --el-main-min-height: 152px
  }

  .el-container.company-container {
    min-height: calc(100vh - 60px - 60px);
  }

  .el-container.company-inner-container {
    min-height: calc(100vh - 60px - 60px - 32px);
  }
}
</style>

<script>
import master from "../../http/apis/master";
import { authenticateJWT, handleCompanySessionExpired, parseJWT } from "../../libs/common";
import store from "../../store";

export default {
  name: "Company",
  data() {
    return {
      menu: {
        activeIndex: null,
      },
      user: {
        name: null,
        email: null,
        companyName: null
      }
    };
  },
  methods: {
    checkSessionExpiry() {
      let jwt = this.$store.getters.companyJWT;
      let pass = true;
      // if(!jwt) {
      //   this.$router.push({name: "CompanyLogin"})
      //   return;
      // } else {
      if (!this.$store.state.company.agreement) {
        this.$router.push({ name: "CompanyAgreement" });
        return;
      }
      pass = authenticateJWT(jwt);
      // }

      if (!pass) {
        handleCompanySessionExpired();
        return false;
      }

      let jwtObj = parseJWT(jwt);
      this.user.name = jwtObj.staff
      this.user.email = jwtObj.email;
      master.getCompanyByCode(this.$i18n.locale, jwtObj["co-code"]).then((response) => {
        this.user.companyName = response.data.text
      });
    },
    logout() {
      this.$store.commit("resetCompanyState");
      this.$router.push({ name: "CompanyLogin" })
    }
  },
  created() {
    this.checkSessionExpiry();
    if (window.history.state.back == null && !authenticateJWT(this.$store.state.company.jwt)) {
      this.$store.commit("resetCompanyState")
      // window.localStorage.removeItem("personal")
    }
  },
  beforeRouteUpdate() {
    this.checkSessionExpiry();
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.company.jwt) {
      next({ name: "CompanyLogin", params: { locale: to.params.locale ?? "en" } })
    } else {
      next()
    }
  }
};
</script>
